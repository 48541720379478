import { FC, useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import Cookies from 'js-cookie';
import VersionSticker from '../version-sticker/VersionSticker';
import { useAuth } from '@/hooks/useAuth';
import { userService } from '@/services/user.service';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import { hideImgOnError } from '@/helpers/helpers';
import styles from './BookCardImg.module.scss';

interface IBookCardImg {
  bookFullAlias?: string;
  title: string;
  coverId: number;
  bookId: number;
  extraClassName?: string;
  isPodcast?: boolean;
  freeVersion?: number;
  type?: string;
}

interface ISendBookData {
  token: string | undefined;
  book_id: string;
  status: string | undefined;
}

const BookCardImg: FC<IBookCardImg> = ({ bookFullAlias, coverId, title, bookId, extraClassName, isPodcast, freeVersion, type }) => {
  const getCardClassNames = () => (extraClassName ? `${styles.imgWrap} ${styles[`${extraClassName}`]}` : styles.imgWrap);

  const { user } = useAuth();

  const [isLibraryActive, setLibraryActive] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [showBookNote, setShowBookNote] = useState(false);

  const refLibraryMenu = useRef<HTMLDivElement>(null);

  const setToggleLibraryActive = () => {
    setLibraryActive(!isLibraryActive);
  };

  const token = Cookies.get('token');

  const sendBookToLibrary = (e: React.SyntheticEvent<EventTarget>) => {
    if (!(e.target instanceof HTMLSpanElement)) {
      return;
    }
    const bookData = {} as ISendBookData;
    bookData.token = token;
    bookData.book_id = String(bookId);
    bookData.status = e.target.dataset.read;
    userService.postUserBook(bookData);
    setShowBookNote(true);
    setTimeout(() => {
      setShowBookNote(false);
    }, 2000);
    setLibraryActive(false);
  };

  useEffect(() => {
    setIsClient(true);

    const handleClickOutside = (event: Event) => {
      if (refLibraryMenu.current && refLibraryMenu.current.contains(event.target as HTMLElement)) {
        return;
      }
      setLibraryActive(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <>
      <div className={getCardClassNames()}>
        {!!bookFullAlias ? (
          <Link prefetch={false} href={bookFullAlias} className={styles.imgLink}>
            <img className={styles.img} width="206" height="290" src={`${DEV_ABSOLUTE_URL}/covers_200/${coverId}.jpg`} alt={title} onError={hideImgOnError} />
          </Link>
        ) : (
          <img className={styles.img} width="206" height="290" src={`${DEV_ABSOLUTE_URL}/covers_200/${coverId}.jpg`} alt={title} onError={hideImgOnError} />
        )}

        {freeVersion && freeVersion > 0 && type !== 'pdf' && <VersionSticker />}

        {!isPodcast && (
          <>
            {user && isClient && token ? (
              <div ref={refLibraryMenu} className={isLibraryActive ? `${styles.library} ${styles.library_active}` : `${styles.library}`}>
                <div className={styles.library__btn} onClick={setToggleLibraryActive}>
                  <span className={styles.library__btnAddTxt}>Добавить </span>В библиотеку
                </div>
                <div className={styles.library__list}>
                  <span className={styles.library__listItem} data-read="reading" onClick={sendBookToLibrary}>
                    Читаю
                  </span>
                  <span className={styles.library__listItem} data-read="want_read" onClick={sendBookToLibrary}>
                    Хочу прочитать
                  </span>
                  <span className={styles.library__listItem} data-read="read" onClick={sendBookToLibrary}>
                    Прочитал
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.library}>
                  <div className={styles.library__btn}>
                    <span className={styles.library__btnAddTxt}>Добавить </span>В библиотеку
                    <span className={styles.library__btnNote}>Авторизуйтесь, чтобы добавить</span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {showBookNote && <div className={styles.library__note}>Книга добавлена</div>}
    </>
  );
};

export default BookCardImg;
